<template>
  <b-container fluid>
    <ValidationObserver ref="appointmentFormObserver" v-slot="{ invalid }">
      <form>
        <iq-card :class-name="isModal ? 'iq-card-nones-shadow' : ''"
                 :body-class="isModal ? 'iq-card-body-modal' : ''">
          <template v-if="!isModal" v-slot:headerTitle>
            <h4 class="card-title">{{ isEdit ? $t("EditAppointment") : $t("NewAppointment") }}</h4>
          </template>
          <template v-slot:body>
            <customer-selection :component-id="model.id" :component-name="'appointment'"></customer-selection>

            <hr />
            <section v-if="isCustomerSelected">

              <b-row>
                <b-form-group class="col-md-6" :label="$t('BranchRoom')" label-for="branchRoom">
                  <ValidationProvider :name="$t('BranchRoom')" rules="required" v-slot="{ errors }">
                    <v-select transition="" v-model="model.roomId"
                              :reduce="s => s.id" label="name"
                              :options="roomList"
                              :class="(errors.length > 0 ? ' is-invalid' : '')"
                              required>
                      <template v-slot:no-options>
                        {{$t('NoMatchingOptionsMessage')}}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group class="col-md-6" :label="$t('Staff')" label-for="staff">
                  <ValidationProvider :name="$t('Staff')" rules="required" v-slot="{ errors }">
                    <v-select transition="" v-model="model.staffId"
                              :reduce="s => s.id" label="name"
                              :options="staffList"
                              :class="(errors.length > 0 ? ' is-invalid' : '')"
                              required>
                      <template v-slot:no-options>
                        {{$t('NoMatchingOptionsMessage')}}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
              <b-row v-if="staffBranchCalendars.length>1">
                <b-form-group class="col-md-12" :label="$t('Calendar')" label-for="calendar">
                  <v-select transition="" v-model="model.staffCalendarId"
                            id="calendar"
                            :disabled="isEdit"
                            :reduce="s => s.id" label="name"
                            :options="staffBranchCalendars"
                            :clearable="false"
                            required>
                    <template v-slot:no-options>
                      {{$t('NoMatchingOptionsMessage')}}
                    </template>
                  </v-select>
                </b-form-group>
              </b-row>
              <b-row v-if="!isEdit" class="d-flex justify-content-center mb-2">
                <b-col md="6" class="text-center align-self-center iq-bg-primary p-1">
                  <label class="mb-0">{{ $t('AppointmentComponent.SelectService') }}</label>
                </b-col>
              </b-row>

              <appointment-service v-for="(as, asIndex) in model.appointmentService" :key="as.id"
                                   :as-index="asIndex" :as-length="model.appointmentService.length"
                                   :initial-as="as" :staff-id="model.staffId"
                                   :staff-branch-calendar-id="model.staffCalendarId"
                                   @addService="addServiceRow" @removeService="removeServiceRow"
                                   @serviceTotalMinute="calculateServiceTotalMinute"></appointment-service>

              <section v-if="isServiceSelected">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('Date')" label-for="date">
                    <date-picker v-model="model.date"
                                 :tag-name="'AppointmentDate'"
                                 :is-required="true" />
                  </b-form-group>
                  <b-form-group class="col-md-6 text-info" :label="$t('HowManyMinutes')" label-for="minute">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-input v-model="roomAvailabilityPeriod" type="text" lazy
                                    :class="(errors.length > 0 || !isPeriodValid ? ' is-invalid' : '')"></b-form-input>
                      <b-form-invalid-feedback>
                        {{ !isPeriodValid ? $t('AppointmentComponent.RoomAvailabilityPeriod') : '' }}
                      </b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>

                <b-row class="text-primary" v-if="serviceTotalMinute >= 5">
                  <b-form-group class="col-md-12" :label="$t('AppointmentComponent.TotalMinute', {minute: serviceTotalMinute })" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group v-model="useTotalMinute" :aria-describedby="ariaDescribedby">
                      <b-form-radio value="1"><span class="text-primary">{{ $t('Yes') }}</span></b-form-radio>
                      <b-form-radio value="0"><span class="text-primary">{{ $t('No') }}</span></b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-row>

                <appointment-room-availability v-if="useManualTimeSelected == 0"
                                               :room-availability-model="roomAvailabilityModel" :is-time-selected="isTimeSelected"
                                               :startTime="model.startTime" :endTime="model.endTime"
                                               @update:start-time="model.startTime = $event"
                                               @update:end-time="model.endTime = $event">
                </appointment-room-availability>

                <b-row class="text-primary" v-if="isServiceSelected">
                  <b-form-group class="col-md-6" :label="$t('AppointmentComponent.ManualTimeSelected')" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group v-model="useManualTimeSelected" :aria-describedby="ariaDescribedby">
                      <b-form-radio value="1"><span class="text-primary">{{ $t('Yes') }}</span></b-form-radio>
                      <b-form-radio value="0"><span class="text-primary">{{ $t('No') }}</span></b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('AppointmentComponent.SetAsVideoCall')" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group v-model="model.createdForOnlineMeeting" :aria-describedby="ariaDescribedby">
                      <b-form-radio value="true"><span class="text-primary">{{ $t('Yes') }}</span></b-form-radio>
                      <b-form-radio value="false"><span class="text-primary">{{ $t('No') }}</span></b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-row>

                <b-row v-if="useManualTimeSelected == 1">
                  <b-form-group class="col-md-6" :label="$t('StartTime')" label-for="startTime">
                    <time-picker v-model="model.startTime" :is-required="true"></time-picker>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('EndTime')" label-for="endTime">
                    <b-form-input v-model="model.endTime " type="text" disabled></b-form-input>
                  </b-form-group>
                </b-row>

                <b-row v-if="useManualTimeSelected==1 && roomAvailabilityForManualSelected.isExist">
                  <b-col md="12">
                    <b-alert :show="true" variant="warning">
                      <div class="iq-alert-icon">
                        <font-awesome-icon icon="fa-regular fa-triangle-exclamation" size="lg" />
                      </div>
                      <div class="iq-alert-text">{{ $t(roomAvailabilityForManualSelected.message) }}</div>
                    </b-alert>
                  </b-col>
                </b-row>

                <b-row>
                  <b-form-group class="col-md-6" v-if="$store.getters['Auth/checkPermission']('appointment_statusedit') || !isEdit"
                                :label="$t('AppointmentStatus')" label-for="status">
                    <ValidationProvider :name="$t('AppointmentStatus')" rules="required" v-slot="{ errors }">
                      <v-select transition="" v-model="model.statusId"
                                :reduce="s => s.id" label="name"
                                :options="appStatusList"
                                :class="(errors.length > 0 ? ' is-invalid' : '')"
                                required>
                        <template v-slot:no-options>
                          {{$t('NoMatchingOptionsMessage')}}
                        </template>
                      </v-select>
                      <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="col-md-6" v-else :label="$t('AppointmentStatus')" label-for="status">
                    <v-select transition="" v-model="model.statusId"
                              :reduce="s => s.id" label="name"
                              :options="appStatusList"
                              :disabled="true">
                      <template v-slot:no-options>
                        {{$t('NoMatchingOptionsMessage')}}
                      </template>
                    </v-select>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('AppointmentType')" label-for="type">
                    <v-select transition="" v-model="model.typeId"
                              :reduce="s => s.id" label="name"
                              :options="appTypeList">
                      <template v-slot:no-options>
                        {{$t('NoMatchingOptionsMessage')}}
                      </template>
                    </v-select>
                  </b-form-group>
                </b-row>

                <b-row>
                  <b-form-group class="col-md-12" :label="$t('Notes')" label-for="notes">
                    <b-form-textarea v-model="model.notes" rows="3"></b-form-textarea>
                  </b-form-group>
                </b-row>
              </section>

            </section>

          </template>
        </iq-card>

        <modal-footer-button v-if="isCustomerSelected"
                             :set-is-submitting="isSubmitting" :is-vue-modal="isVueModal"
                             @submit="submitNewAppointment"></modal-footer-button>

      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
  import staffService from '../../../services/staff'
  import branchRoomService from '../../../services/branchRoom'
  import commonService from '../../../services/common'
  import appointmentService from '../../../services/appointment'

  import { getToday } from '../../../utils/dateTimeExtensions'
  import { refreshSmartDuoData } from '../../../helpers/dataRefresher'

  import CustomerSelection from '../../../components/customer/CustomerSelection'
  import AppointmentService from '../../../components/appointment/AppointmentService'
  import AppointmentRoomAvailability from '../../../components/appointment/AppointmentRoomAvailability'
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'
  import DatePicker from '../../../components/shared/DatePicker'
  import TimePicker from '../../../components/shared/TimePicker'

  export default {
    name: 'NewAppointment',
    props: {
      appointmentId: String,
      customerId: String,
      isModal: Boolean
    },
    components: {
      CustomerSelection,
      AppointmentService,
      AppointmentRoomAvailability,
      ModalFooterButton,
      DatePicker,
      TimePicker
    },
    data() {
      return {
        selectedCustomerId: '',
        model: {
          date: getToday(),
          customerId: '',
          roomId: '',
          staffId: '',
          statusId: '',
          typeId: '',
          startTime: '',
          endTime: '',
          notes: '',
          disableSurveyNotifications: true,
          createdForOnlineMeeting: false,
          appointmentService: [{}],
          deletedAppointmentService: [],
          staffCalendarId: null
        },

        roomList: [],
        staffList: [],
        serviceList: [],
        appStatusList: [],
        appTypeList: [],
        roomAvailabilityModel: [],
        roomAvailabilityForManualSelected: {},
        staffBranchCalendars: [],

        roomAvailabilityPeriod: 30,
        useManualTimeSelected: 0,
        useTotalMinute: 0,
        serviceTotalMinute: 0,
        periodChange: false,
        isReadyForTimeCheck: true,
        isSubmitting: false
      }
    },
    methods: {
      async submitNewAppointment() {
        const isValid = await this.$refs.appointmentFormObserver.validate();
        if (!isValid) {
          this.$toastr.error(this.$t('ValidationMessage.EmptyAndValidateError'));
          return;
        }

        if (this.isAvailability && this.isTimeSelected) {
          this.isSubmitting = true;
          let url = '/appointment/new';
          if (this.isEdit && this.isEdit.length > 0)
            url = '/appointment/edit'

          appointmentService.submitAppointmentForm(url, this.model)
            .then(response => {
              this.isSubmitting = false;
              if (response && response.success) {
                this.$toastr.success(this.$t('Success'));

                if (this.$router.currentRoute.name == 'appointment.modalEdit' || this.$router.currentRoute.name == 'appointment.modalEditFromCustomerSummary') {
                  this.$emit('closeModal');
                  refreshSmartDuoData();
                }
                else if (this.$router.currentRoute.name.includes('calendar')) {
                  this.$modal.hide('vue-modal');
                }
                else {
                  this.$router.push({ name: 'customerSummary.appointments', params: { customerId: this.model.customerId } });
                }
              }
              else {
                if (response && response.message)
                  this.$toastr.error(this.$t(response.message));
                else
                  this.$toastr.error(this.$t('Error'));
              }
            }).finally(() => { this.isSubmitting = false; });
        }
      },
      getAppointment() {
        if (this.isEdit) {
          this.isReadyForTimeCheck = false;
          appointmentService.getAppointment(this.appointmentId).then(response => {
            if (response) {
              this.model = response;
              this.$bus.$emit('CustomerSelectionComponent_onSelected', response.customerId);
              this.getPrimitives();
            }
          }).finally(() => {
            this.isReadyForTimeCheck = true;
            this.useManualTimeSelected = 1;
          });
        }
      },
      getBranchRoomList() {
        let paramId = this.model && this.model.id && this.model.roomId ? { id: this.model.roomId } : {};

        branchRoomService.getBranchRoomList(paramId)
          .then(response => { this.roomList = response; });
      },
      getStaffList() {
        let paramId = (this.model && this.model.id && this.model.staffId)
          ? { params: { id: this.model.staffId, forSaleRep: 0, onlyShowInCalendar: 1 } }
          : { params: { id: null, forSaleRep: 0, onlyShowInCalendar: 1 } };

        staffService.getStaffList(paramId)
          .then(response => { this.staffList = response; });
      },
      getStaffBranchCalendar(staffId) {
        commonService.getStaffBranchCalendar(staffId)
          .then(response => {
            this.staffBranchCalendars = response;

            if (this.staffBranchCalendars.length > 0 && !this.isEdit) {
              this.staffBranchCalendars.forEach(calendar => {
                if (calendar.isSelected) {
                  this.model.staffCalendarId = calendar.id;
                }
              });
            }
          });
      },
      getPrimitives() {
        this.getBranchRoomList();
        this.getStaffList();
        this.getAppointmentStatusList();
        this.getAppointmentTypeList();
      },
      addServiceRow() {
        this.model.appointmentService.push({});
      },
      removeServiceRow(service) {
        if (this.isEdit && service.id && service.id.length > 0) {
          this.model.deletedAppointmentService.push(service.id);
        }
        this.model.appointmentService.splice(this.model.appointmentService.indexOf(service), 1);
      },
      getRoomAvailability() {

        let params = {
          roomId: this.model.roomId,
          staffId: this.model.staffId,
          _date: this.model.date,
          _startTime: this.model.startTime,
          _endTime: this.model.endTime,
          period: this.roomAvailabilityPeriod,
          appointmentId: this.appointmentID,
          periodChange: this.periodChange
        }

        appointmentService.getRoomAvailability(params)
          .then(response => {
            this.roomAvailabilityModel = response;
            if (this.roomAvailabilityModel)
              this.roomAvailabilityPeriod = this.roomAvailabilityModel[0].value[0].period;
            else
              this.roomAvailabilityModel = []

            this.periodChange = false;

          });
      },
      getRoomAvailabilityForManualSelected() {

        this.model.endTime = this.$moment(this.model.startTime, 'HH:mm').add(this.roomAvailabilityPeriod, 'minutes').format('HH:mm');

        if (this.model.endTime > this.$store.getters.calendarEndTime)
          this.model.endTime = this.$store.getters.calendarEndTime;

        if ((this.model.roomId && this.model.roomId.length > 0) || (this.model.staffId && this.model.staffId.length > 0)) {

          let params = {
            appointmentId: this.appointmentID,
            roomId: this.model.roomId,
            staffId: this.model.staffId,
            date: this.model.date,
            startTime: this.model.startTime,
            endTime: this.model.endTime
          }

          appointmentService.getRoomAvailabilityForManualSelected(params)
            .then(response => {
              this.roomAvailabilityForManualSelected = response;
              this.periodChange = false;
            });
        }
      },
      getAppointmentStatusList() {
        commonService.getAppointmentStatusList().then(response => {
          this.appStatusList = response;
          if (!this.isEdit) {
            let defaultStatus = this.appStatusList.find(z => z.isDefault);
            if (defaultStatus)
              this.model.statusId = defaultStatus.id;
          }
        });
      },
      getAppointmentTypeList() {
        commonService.getAppointmentTypeList().then(response => { this.appTypeList = response; });
      },
      getServiceMinute(serviceId) {
        appointmentService.getServiceMinute(serviceId)
          .then(response => {
            if (response >= 5)
              this.serviceTotalMinute += response;
          });
      },
      calculateServiceTotalMinute() {
        this.serviceTotalMinute = 0;
        this.model.appointmentService.forEach(el => {
          if (el.serviceId != null && el.serviceId.length > 0) {
            this.getServiceMinute(el.serviceId);
          }
        });
      }
    },
    watch: {
      'model.date': function (val) {
        this.model.date = val;
        if (this.isAvailability && this.isReadyForTimeCheck) {
          if (this.useManualTimeSelected == 1)
            this.getRoomAvailabilityForManualSelected()
          else
            this.getRoomAvailability();
        }
      },
      'model.staffId': function (val) {
        this.model.staffId = val;

        this.getStaffBranchCalendar(this.model.staffId);

        if (this.isAvailability && this.isReadyForTimeCheck) {
          if (this.useManualTimeSelected == 1)
            this.getRoomAvailabilityForManualSelected()
          else
            this.getRoomAvailability();
        }
      },
      'model.roomId': function (val) {
        this.model.roomId = val;
        if (this.isAvailability && this.isReadyForTimeCheck) {
          if (this.useManualTimeSelected == 1)
            this.getRoomAvailabilityForManualSelected()
          else
            this.getRoomAvailability();
        }
      },
      'model.startTime': function (val) {
        if (val && this.useManualTimeSelected == 1) {

          this.model.endTime = this.$moment(this.model.startTime, 'HH:mm').add(this.roomAvailabilityPeriod, 'minutes').format('HH:mm');

          if (this.model.endTime > this.$store.getters['Calendar/calendarEndTime'])
            this.model.endTime = this.$store.getters['Calendar/calendarEndTime'];

          this.getRoomAvailabilityForManualSelected();
        }
      },
      roomAvailabilityPeriod: function (val) {
        this.roomAvailabilityPeriod = val;
        this.periodChange = true;
        if (this.isAvailability && this.isReadyForTimeCheck) {
          if (this.useManualTimeSelected == 1) {
            this.getRoomAvailabilityForManualSelected()
          }
          else {
            this.model.endTime = this.$moment(this.model.startTime, 'HH:mm').add(this.roomAvailabilityPeriod, 'minutes').format('HH:mm');

            if (this.model.endTime > this.$store.getters['Calendar/calendarEndTime'])
              this.model.endTime = this.$store.getters['Calendar/calendarEndTime'];

            this.getRoomAvailability();
          }
        }
      },
      useTotalMinute: function (val) {
        if (val == 1) {
          this.model.endTime = this.$moment(this.model.startTime, 'HH:mm').add(this.serviceTotalMinute, 'minutes').format('HH:mm');

          if (this.model.endTime > this.$store.getters['Calendar/calendarEndTime'])
            this.model.endTime = this.$store.getters['Calendar/calendarEndTime'];

          this.roomAvailabilityPeriod = this.serviceTotalMinute;
        }
        else {
          this.roomAvailabilityPeriod = 30;
        }
      },
      useManualTimeSelected: function (val) {
        if (val == 0 && this.isAvailability && this.isReadyForTimeCheck)
          this.getRoomAvailability();
      },
      isAvailability: function (val) {
        if (val) {
          if (this.useManualTimeSelected == 1)
            this.getRoomAvailabilityForManualSelected()
          else
            this.getRoomAvailability();
        }
      }
    },
    computed: {
      isEdit: function () { return this.appointmentId && this.appointmentId.length > 0 },
      isPeriodValid: function () { return this.roomAvailabilityPeriod >= 5 && this.roomAvailabilityPeriod <= 540; },
      isCustomerSelected: function () { return this.selectedCustomerId && this.selectedCustomerId.length > 0; },
      isRoomSelected: function () { return this.model.roomId && this.model.roomId.length > 0; },
      isStaffSelected: function () { return this.model.staffId && this.model.staffId.length > 0; },
      isTimeSelected: function () {
        return (this.model.startTime && this.model.startTime.length > 0) && (this.model.endTime && this.model.endTime.length > 0);
      },
      isServiceSelected: function () {
        return this.model.appointmentService && this.model.appointmentService.filter(el => el.serviceId && el.serviceId.length > 0).length > 0;
      },
      isAvailability: function () {
        return this.isCustomerSelected && this.isRoomSelected && this.isStaffSelected && this.isServiceSelected && this.isPeriodValid;
      },
      isVueModal: function () { return this.$router.currentRoute.name.includes('calendar'); }
    },
    created: function () {
      this.$bus.$on('CustomerSelectionComponent_onSelected', (value) => {
        this.selectedCustomerId = value;
        this.model.customerId = value;
      });
    },
    destroyed() {
      this.$bus.$off('CustomerSelectionComponent_onSelected');
    },
    mounted() {
      if (!this.appointmentId && this.customerId) {
        this.$bus.$emit('CustomerSelectionComponent_onSelected', this.customerId);
      }

      if (!this.appointmentId) {
        this.getPrimitives();
      }
      else {
        this.getAppointment();
      }
    }
  }
</script>

<style>
  .iq-card-nones-shadow {
    box-shadow: none !important;
  }

  .iq-card-body-modal {
    padding: 0px !important;
  }
</style>
